<template>
  <div>
    <el-dialog
      title="新增水单号"
      :visible.sync="dialogTableVisible"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <span>
        <el-form
          ref="formRules"
          :model="formData"
          :rules="formRules"
          label-width="100px"
        >
          <el-form-item label="开始水单号" prop="startBillNo">
            <el-input
              v-model="formData.startBillNo"
              placeholder="开始水单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="结束水单号" prop="endBillNo">
            <el-input
              v-model="formData.endBillNo"
              placeholder="结束水单号"
            ></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="float: left;"
          type="primary"
          size="defualt"
          @click="onTempCreate"
          >临时生成</el-button
        >
        <el-button @click="onCloseDialog('formRules')">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import setting from "@/api/setting.js";
export default {
  props: {
    etitle: {
      type: String,
      default: "添加",
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      formData: {
        startBillNo: "",
        endBillNo: "",
      },
      formRules: {
        startBillNo: [
          { required: true, message: "请输入开始水单号", trigger: "submit" },
          // { type: "number", message: "只能输入数字" },
        ],
        endBillNo: [
          { required: true, message: "请输入结束水单号", trigger: "submit" },
          // { type: "number", message: "只能输入数字" },
        ],
      },
    };
  },
  methods: {
    onTempCreate() {
      let { formData } = this;
      let nowTime = this.util.dateFormat("YYYYmmdd", new Date()).slice(2);
      formData.startBillNo = Number(nowTime + "500");
      formData.endBillNo = Number(nowTime + "599");
      // console.log(nowTime);
    },

    openAddWaterAingle() {
      this.dialogTableVisible = true;
    },

    closeAddWaterAingle() {
      this.dialogTableVisible = false;
    },

    onCloseDialog(formName) {
      this.$refs[formName].resetFields();
      this.dialogTableVisible = false;
      this.$emit("closeDialog");
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(/\D/.test(this.formData.startBillNo)){
            return this.$message.error("开始水单号只能输入数字");
          }else if(/\D/.test(this.formData.endBillNo)){
            return this.$message.error("结束水单号只能输入数字");
          }
          try {
            let submitData = {
              startBillNo: this.formData.startBillNo,
              endBillNo: this.formData.endBillNo,
            };
            let { data, errcode, errmsg } = await setting.addBillNo(submitData);
            if (errcode == 0) {
              this.onCloseDialog("formRules");
              this.$message({
                message: "成功!",
                type: "success",
              });
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {}
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  width: 100%;
}
</style>
